@charset "UTF-8";

:root {
  --borad-20: 20px;
  --borad-30: 30px;
  --color-main: var(--color-blue-300);
  --color-green-primary: #7ab929;
  --color-green-500: #a9cc8a;
  --color-green-transparent: rgba(139, 190, 92, 0.4);
  --color-blue-primary: #004079;
  --color-blue-100: #161d2d;
  --color-blue-200: #1d2f3f;
  --color-blue-300: #656970;
  --color-blue-400: #8991a2;
  --color-blue-500: #bfc3ca;
  --color-blue-600: #dfe0e3;
  --color-blue-700: #f1f1f2;
  --color-blue-800: #f7f7f8;
  --color-white: #fff;
  --color-white-transparent: rgba(255, 255, 255, 0.3);
  --color-red: #ff4747;
  --color-orange: #ffd43a;
  --color-btn-hover: #6ba027;
  --bg-dark: var(--color-blue-200);
  --bg-light: var(--color-blue-700);
  --swiper-theme-color: var(--color-blue-100);
  --font-main: "Montserrat", sans-serif;
  --animate-delay: 0.5s;
}

@font-face {
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
* {
    // scroll-behavior: smooth;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  margin: 0 auto;
  height: 100%;
  scrollbar-color: var(--color-green-primary) var(--color-blue-700);
  /* 1-«цвет ползунка», 2-«цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

html ::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

html ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--color-blue-700);
}

html ::-webkit-scrollbar-thumb {
  border: 1px solid var(--color-blue-700);
  border-radius: 10px;
  background-color: var(--color-green-primary);
}

html ::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-btn-hover);
}

body {
  margin-left: 5.3vw;
  height: 100%;
  width: 94.7%;
  font-family: var(--font-main);
  font-size: 18px;
  line-height: 160%;
  color: var(--color-main);
}

body.lock {
  width: 100%;
}

body.lock.header-menu-mod {
  width: 94.7%;
}

.wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}

.content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-left: auto;
  width: 100%;
}

.footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  &__logo {
      margin-bottom: 53px;
  }
  .icon-block__info span.work-time {
    color: #ffffff;
  }
}

.footer_subscribe {
    max-width: 210px;
    &_title {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    &_subtitle {
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 15px;
    }
    &_info {
        font-size: 12px;
        color: var(--color-green-primary);
        margin: 0;
        line-height: 1.5;
    }
    form {
        position: relative;
        padding-top: 10px;
        button[type="submit"] {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 17px;
            height: 17px;
            padding: 0;
            background-image: url(../img/icons/arrow-right-white.svg);
        }
        input[type="email"] {
            font-size: 16px;
            display: block;
            width: 100%;
            padding: 10px 23px 10px 0;
            background-color: transparent;
            color: rgba(256, 256, 256, 0.3);
            border-color: rgba(256, 256, 256, 0.3);
        }
    }
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 86.5vw;
  &.container_small {
      max-width: 1078px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: var(--font-main);
  color: var(--color-blue-100);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
#certificate-list .certificate__image {
    display: flex;
    justify-content: center;
}
#certificate-list picture {
    width: 70%;
}
#certificate-list .certificate {
    text-align: center;
}
.title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 35px;
  border-bottom: 1px solid var(--color-blue-500);
  padding-bottom: 30px;
  color: var(--color-blue-100);
}

.title span {
  color: var(--color-green-primary);
}

.title.white {
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white-transparent);
}

.title-h1 {
  font-weight: 600;
  font-size: 105px;
  line-height: 1.05;
  margin-bottom: 35px;
  border-bottom: none;
  color: var(--color-blue-100);
}

.title-h1.white {
  color: var(--color-white);
  border-bottom: none;
}

.title-h2 {
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 70px;
  line-height: 1.2;
  color: var(--color-blue-100);
}

.title-h2.white {
  color: var(--color-white);
}

.title-h3 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  color: var(--color-blue-100);
}

.title-h3.white {
  color: var(--color-white);
}

.title-h4 {
  margin-bottom: 1.1em;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.4;
  color: var(--color-blue-100);
}

.title-h4.white {
  color: var(--color-white);
}

.title-h5 {
  margin-bottom: 0.8em;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: var(--color-blue-100);
}

.title-h5.white {
  color: var(--color-white);
}

section {
  padding-top: 50px;
  padding-bottom: 50px;
}

picture {
  display: block;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
}

a {
  display: inline-block;
  text-decoration: none;
  color: var(--color-main);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover {
  color: var(--color-blue-400);
}

input,
textarea {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--color-blue-600);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

input:hover,
input:focus-visible,
textarea:hover,
textarea:focus-visible {
  border-color: var(--color-blue-100);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

button,
input[type=submit] {
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

input[type=file] {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 0;
}

textarea {
  resize: none;
}

ul {
  margin-top: 0;
  padding-left: 30px;
}

.lock {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.popup__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 10px;
  min-height: 100%;
}

.popup__content {
  position: relative;
  padding: 30px;
  max-width: 800px;
  color: var(--color-main);
  background-color: #fff;
  opacity: 0;
  -webkit-transform: translateY(-100vh);
  -ms-transform: translateY(-100vh);
  transform: translateY(-100vh);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.popup__close {
  position: absolute;
  right: 15px;
  top: 10px;
  padding: 10px;
}

.popup__title {
  margin-bottom: 20px;
  font-size: 30px;
}
.response-succes {
    margin-bottom: 0;
}
.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup.open .popup__content {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

._anim p,
._anim a:not(.news-card__btn, .product-card__btn),
._anim span:not(.swiper-pagination-bullet, .request-form__field span),
._anim picture,
._anim input,
._anim button,
._anim div:not(.swiper-button-prev, .swiper-button-next, header, .swiper-slide, .swiper-wrapper),
._anim:not(.visually-hidden) {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: opacity 0.8s, background-color 0.5s, border-color 0.5s, color 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.8s, background-color 0.5s, border-color 0.5s, color 0.5s, -webkit-transform 0.5s;
  -o-transition: opacity 0.8s, transform 0.5s, background-color 0.5s, border-color 0.5s, color 0.5s;
  transition: opacity 0.8s, transform 0.5s, background-color 0.5s, border-color 0.5s, color 0.5s;
  transition: opacity 0.8s, transform 0.5s, background-color 0.5s, border-color 0.5s, color 0.5s, -webkit-transform 0.5s;
}

._anim.elem-show p,
._anim.elem-show a:not(.news-card__btn, .product-card__btn),
._anim.elem-show span:not(.swiper-pagination-bullet, .request-form__field span),
._anim.elem-show picture,
._anim.elem-show input,
._anim.elem-show button,
._anim.elem-show div:not(.swiper-button-prev, .swiper-button-next, header, .swiper-slide, .swiper-wrapper),
._anim.elem-show:not(.visually-hidden) {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.bg-white {
  background-color: #fff;
}

.bg-light {
  background-color: var(--bg-light);
}

.bg-dark {
  background-color: var(--bg-dark);
}

.two-in-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px;
}

.two-in-row__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}

.two-in-row__column.cols4 {
  width: 33.33%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
}

.two-in-row__column.cols8 {
  width: 66.66%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66%;
  flex: 0 0 66.66%;
}

.three-in-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px;
}

.three-in-row__column {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 30%;
  flex: 0 1 30%;
}

.colored_link {
  text-decoration: underline;
  color: #7ab929;
}

.btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  padding: 0 30px;
  height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  background-color: var(--color-green-primary);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.btn:hover,
.btn:focus-visible {
  color: #fff;
  background-color: var(--color-btn-hover);
}

.btn.arrow-left {
  padding-left: 60px;
}

.btn.arrow-left::after {
  content: "";
  position: absolute;
  left: 30px;
  top: 50%;
  width: 20px;
  height: 20px;
  background-image: url("../img/icons/arrow-left-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn.arrow-right {
  padding-right: 60px;
}

.btn.arrow-right::after {
  content: "";
  position: absolute;
  right: 30px;
  top: 50%;
  width: 20px;
  height: 20px;
  background-image: url("../img/icons/arrow-right-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn.long {
  width: 100%;
}

.btn.disabled {
  color: var(--color-blue-500);
  background-color: var(--color-blue-700);
  cursor: default;
}

.btn.white {
  color: var(--color-blue-100);
  background-color: #fff;
}

.btn.white:hover,
.btn.white:focus-visible {
  color: var(--color-white);
  background-color: var(--color-blue-100);
}

.more-btn.icon-block {
  text-transform: uppercase;
  margin: 65px auto 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--color-blue-100);
}

.swiper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.swiper-slide {
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  width: 45px;
  height: 45px;
  top: auto;
  border: 1px solid var(--color-blue-600);
  border-radius: 50%;
  -webkit-transition: border 0.5s;
  -o-transition: border 0.5s;
  transition: border 0.5s;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 19px;
  height: 19px;
  font-size: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-button-prev {
  margin-right: 10px;
}

.swiper-button-prev::after {
  background-image: url("../img/icons/arrow-left.svg");
}

.swiper-button-prev:hover {
  border-color: var(--color-blue-100);
}

.swiper-button-prev.white::after {
  background-image: url("../img/icons/arrow-left-white.svg");
}

.swiper-button-next::after {
  background-image: url("../img/icons/arrow-right.svg");
}

.swiper-button-next:hover {
  border-color: var(--color-blue-100);
}

.swiper-button-next.white::after {
  background-image: url("../img/icons/arrow-right-white.svg");
}

.swiper-pagination.white .swiper-pagination-bullet {
  background-color: var(--color-white);
}

.swiper-pagination-bullet {
  width: 7px;
  height: 7px;
}

.popup-request.popup {
  background-image: url("../img/popup-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.popup-request.popup::before {
  content: "";
  position: absolute;
  left: 10%;
  top: 0;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: rgba(139, 190, 92, 0.75);
  -webkit-filter: blur(450px);
  filter: blur(450px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.popup-request.popup .popup__close {
  top: 15px;
  right: -45px;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.popup-request.popup .popup__body {
  background-color: rgba(25, 36, 57, 0.75);
  padding: 50px 100px;
}

.popup-request.popup .popup__content {
  padding: 0;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
}
.popup__content--success {
    display: flex;
    align-items: center;
    border-radius: 20px;
}
.popup-request.popup .popup__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.popup-request.popup .popup__logo picture {
  width: 100%;
  max-width: 330px;
}

.popup-request.popup .popup__logo img {
  width: 100%;
}

.popup-request.popup .request-form {
  width: 100%;
  max-width: 800px;
}

.popup-request.popup .request-form__title {
  margin-top: 0;
  padding-right: 40px;
}
.popup-request.popup .request-form__title span {
    font-weight: 500;
}
.popup-request.popup .request-form__file .icon-block {
  color: var(--color-blue-100);
}

.breadcrumbs__list {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 16px 0;
}

.breadcrumbs__list li {
  position: relative;
  padding-right: 31px;
}

.breadcrumbs__list li:not(:last-child) {
  margin-right: 15px;
}

.breadcrumbs__list li:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  width: 16px;
  height: 16px;
  background: url("../img/icons/chevron-right.svg") no-repeat center/cover;
}

.breadcrumbs__list a,
.breadcrumbs__list span {
  font-size: 14px;
  line-height: 1.55;
}

.breadcrumbs__list a {
  color: var(--color-blue-300);
}

.breadcrumbs__list a:hover {
  color: var(--color-green-primary);
}

.breadcrumbs__list span {
  color: var(--color-green-primary);
}

.about-links {
  padding-bottom: 0;
}

.about-links__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}

.about-links__slider {
  padding-bottom: 10px;
  min-height: 50px;
}

.about-links__slider .swiper-slide {
  width: auto;
}

.about-links .btn {
  margin: 5px;
  border: 1px solid var(--color-blue-100);
}

.about-links .btn:hover,
.about-links .btn:focus-visible,
.about-links .btn.white.active {
  color: var(--color-white);
  background-color: var(--color-blue-100);
}

.about-progress {
  position: relative;
  z-index: 1;
  padding: 0;
}

.about-progress__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
}

.about-progress__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 calc(33.3333333333% - 10px);
  flex: 1 0 calc(33.3333333333% - 10px);
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: var(--borad-30);
  padding: 35px 40px;
  min-width: 290px;
  min-height: 250px;
  background-color: var(--color-blue-700);
  -webkit-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.about-progress__item:first-child {
  color: #fff;
  background-color: var(--color-green-primary);
}

.about-progress__item:first-child > .about-progress__item-title {
  color: #fff;
}

.about-progress__item p {
  margin: 0;
}

.about-progress__item-title {
  font-weight: 600;
  line-height: 1.2;
  color: var(--color-blue-100);
  -webkit-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.about-progress__text {
  margin-top: auto;
}

.icon-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 50px;
  font-weight: 600;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.icon-block:hover .icon-block__icon {
  border: 1px solid currentColor;
}

.icon-block__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
  border: 1px solid var(--color-blue-600);
  border-radius: 50%;
  padding: 12px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.icon-block__icon svg {
  width: 19px;
  height: 19px;
}

.icon-block__icon svg path {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  stroke: currentColor;
}

.icon-block__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: inherit;
}

.icon-block__info button,
.icon-block__info .footer__text {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}

.icon-block__info span {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-blue-300);
}
.icon-block__info .phone-text {
    font-weight: 600;
    font-size: 18px;
    color: #161D2D;   
}
.icon-block__info .footer__text {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
}
.icon-block__info span.work-time {
  color: var(--color-blue-300);
}

.header {
  position: sticky;
  top: 0;
  z-index: 990;
  background-color: #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-blue-600);
}

.header.dark {
  background-color: var(--color-blue-200);
  & .phone-text {
      color: #fff;
  }
}

.header.dark::after {
  background-color: var(--color-white-transparent);
}

.header.dark .logo__link {
  color: #fff;
}

.header.dark .logo__link:hover {
  color: var(--color-blue-400);
}

.header.dark .logo__descr {
  border-color: var(--color-white-transparent);
  color: var(--color-blue-400);
}

.header.dark .header__social {
  border-color: var(--color-white-transparent);
}

.header.dark .header__work-time {
  color: var(--color-blue-400);
}

.header.dark .ham .line {
  stroke: #fff;
}

.header.dark .icon-block {
  color: #fff;
}

.header.dark .icon-block__icon {
  border-color: var(--color-white-transparent);
}

.header.dark .icon-block:hover a {
  color: #fff !important;
  border-color: var(--color-white-transparent);
}

.header.dark .icon-block:hover .icon-block__icon {
  border-color: #fff;
}

.header__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  padding: 15px;
  min-height: 100px;
}

.header__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__social {
  margin-right: 25px;
  border-right: 1px solid var(--color-blue-600);
  padding-right: 25px;
}

.header__social--top {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.header__social img {
  width: 45px;
  height: 45px;
}

.header__phone,
.header__phone.icon-block {
  white-space: nowrap;
  color: var(--color-blue-100);
}

.header__phone:hover a,
.header__phone.icon-block:hover a {
  color: var(--color-blue-100);
}

.header__btn {
  color: var(--color-white);
}

.header__btn:hover span {
  color: var(--color-white);
}

.header__btn-icon {
  display: none;
  width: 19px;
  height: 19px;
}

.header__btn-icon path {
  stroke: #fff;
}

.header__btn-text {
  color: var(--color-white);
}

.header__mob-btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
}

.header__mob-btn .ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header__mob-btn .hamRotate.active {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__mob-btn .line {
  fill: none;
  -webkit-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  -o-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.header__mob-btn .ham8 .top {
  stroke-dasharray: 40 160;
}

.header__mob-btn .ham8 .middle {
  stroke-dasharray: 40 142;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
}

.header__mob-btn .ham8 .bottom {
  stroke-dasharray: 40 85;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: stroke-dashoffset 400ms, -webkit-transform 400ms;
  transition: stroke-dashoffset 400ms, -webkit-transform 400ms;
  -o-transition: transform 400ms, stroke-dashoffset 400ms;
  transition: transform 400ms, stroke-dashoffset 400ms;
  transition: transform 400ms, stroke-dashoffset 400ms, -webkit-transform 400ms;
}

.header__mob-btn .ham8.active .top {
  stroke-dashoffset: -64px;
}

.header__mob-btn .ham8.active .middle {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.header__mob-btn .ham8.active .bottom {
  stroke-dashoffset: -64px;
}

.header__menu {
  position: fixed;
  top: 100px;
  left: 5.3vw;
  right: 0;
  z-index: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  padding: 75px 5vw;
  height: calc(100% - 100px);
  overflow-y: auto;
  color: #fff;
  background-color: var(--color-blue-200);
  background-image: url("../img/round-bg.svg");
  background-repeat: no-repeat;
  background-position: right 0px top 300px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  scrollbar-color: var(--color-green-primary) var(--color-blue-700);
  /* 1-«цвет ползунка», 2-«цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

.header__menu ::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.header__menu ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--color-blue-700);
}

.header__menu ::-webkit-scrollbar-thumb {
  border: 1px solid var(--color-blue-700);
  border-radius: 10px;
  background-color: var(--color-green-primary);
}

.header__menu ::-webkit-scrollbar-thumb:hover {
  background-color: #6ea725;
}

.header__menu.active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.header__menu a {
  color: #fff;
}

.header__menu ul {
  list-style: none;
  padding-left: 0;
}

.header__menu .header__social {
  margin-right: auto;
  border: none;
}

.header__menu .header__social a {
  margin-right: 20px;
}

.header__menu-bottom {
  border-top: 1px solid var(--color-white-transparent);
  padding: 90px 0;
}

.header__menu-bottom .icon-block {
  color: #fff;
  margin-right: 50px;
}

.header__menu-bottom .icon-block__icon {
  border-color: var(--color-white-transparent);
}

.header__menu-bottom .icon-block:hover a {
  color: #fff !important;
}

.header__address {
  margin-right: auto;
}

.header__address.icon-block {
  margin-right: 0;
}

.header__address .icon-block__info {
  font-weight: 400;
  font-size: 14px;
}

.header__mail {
  margin-right: 70px;
}

.header__nav-list > li {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: calc(30px + 2 * (100vw - 320px) / 1600);
  line-height: 1.2;
}

.header__produce {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 15px;
}

.header__produce-title {
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: calc(30px + 2 * (100vw - 320px) / 1600);
  line-height: 1.2;
}

.header__produce-list li {
  margin-bottom: 23px;
}

.header__produce-list a {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
}

.header a:hover {
  color: var(--color-blue-400);
}

.header .two-in-row {
  padding-bottom: 20px;
}

.header .two-in-row__column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48%;
  flex: 0 0 48%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 196px;
  color: var(--color-blue-primary);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.logo__descr {
  display: inline-block;
  margin-left: 25px;
  border-left: 1px solid var(--color-blue-600);
  padding-left: 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.7;
  color: var(--color-blue-300);
  text-transform: uppercase;
}

.logo .switch-color {
  fill: currentColor;
}

.footer {
  background-color: var(--color-blue-200);
}

.footer__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 100px 0;
}

.footer__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__row:first-child {
  margin-bottom: 50px;
  border-bottom: 1px solid var(--color-white-transparent);
  padding-bottom: 50px;
}

.footer__logo a {
  color: #fff;
}

.footer__logo a:hover {
  color: var(--color-blue-400);
}

.footer__title {
  color: #fff;
}

.footer__list li {
  margin-bottom: 12px;
}

.footer__list a {
  color: var(--color-blue-400);
}

.footer__list a:hover {
  color: var(--color-blue-600);
}

.footer__contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer .icon-block {
  color: #fff;
}

.footer .icon-block__icon {
  border-color: var(--color-white-transparent);
}

.footer__social img {
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.footer__address {
  max-width: 500px;
}

.footer__address .icon-block__info {
  font-weight: 400;
}

.footer__bottom {
  background-color: var(--color-blue-100);
}

.footer__bottom-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 0;
  min-height: 100px;
  color: var(--color-blue-300);
}

.footer__bottom a {
  color: var(--color-blue-300);
}

.footer__bottom a:hover {
  color: var(--color-blue-400);
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.object .product__title {
    margin-bottom: 50px;
    padding-bottom: 30px;
    
}

.object-slider {
    padding-bottom: 75px;
    margin-bottom: 40px;
    .product-slider__slide {
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        .product-slider__image {
            width: 100%;
            img {
                width: 100%;
                
            }
        }
    }
}

.object-slider__next, .object-slider__prev {
    bottom: 0;
}

.request-form {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 534px;
  border: 1px solid var(--color-blue-600);
  border-radius: var(--borad-30);
  padding: 55px 60px 60px;
  background-color: #fff;
}

.request-form__title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 36px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.4;
  color: var(--color-blue-100);
}

.request-form__field {
  position: relative;
  margin-bottom: 35px;
  color: var(--color-blue-300);
}

.request-form__field .icon-block__info span {
  top: 0;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-blue-300);
}

.request-form label {
  display: block;
  width: 100%;
  cursor: pointer;
  z-index: 0;
}

.request-form input,
.request-form textarea {
  width: 100%;
  min-height: 32px;
  padding: 7px 3px;
  color: var(--color-blue-100);
}

.request-form input::-webkit-input-placeholder, .request-form textarea::-webkit-input-placeholder {
  font-size: 1px;
  line-height: 1;
  color: transparent;
}

.request-form input::-moz-placeholder, .request-form textarea::-moz-placeholder {
  font-size: 1px;
  line-height: 1;
  color: transparent;
}

.request-form input:-ms-input-placeholder, .request-form textarea:-ms-input-placeholder {
  font-size: 1px;
  line-height: 1;
  color: transparent;
}

.request-form input::-ms-input-placeholder, .request-form textarea::-ms-input-placeholder {
  font-size: 1px;
  line-height: 1;
  color: transparent;
}

.request-form input::placeholder,
.request-form textarea::placeholder {
  font-size: 1px;
  line-height: 1;
  color: transparent;
}

.request-form__field--input {
  width: 100%;
  color: var(--color-blue-100);
}

.request-form__field--input .input-placeholder {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1;
  display: inline-block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.request-form__field--input textarea:focus,
.request-form__field--input textarea:valid,
.request-form__field--input input:focus,
.request-form__field--input input:valid {
  border-color: var(--color-blue-100);
}

.request-form__field--input textarea:focus + .input-placeholder,
.request-form__field--input textarea:valid + .input-placeholder,
.request-form__field--input input:focus + .input-placeholder,
.request-form__field--input input:valid + .input-placeholder {
  font-size: 14px;
  -webkit-transform: translateX(0px) translateY(-20px);
  -ms-transform: translateX(0px) translateY(-20px);
  transform: translateX(0px) translateY(-20px);
}

.request-form__field--input .form-error {
  position: absolute;
  display: none;
  margin: 0;
  font-size: 14px;
}

.request-form__field--input input:invalid:focus ~ .form-error,
.request-form__field--input textarea:invalid:focus ~ .form-error {
  display: block;
  color: #EB5757;
}

.request-form__file {
  width: 100%;
  margin-bottom: 35px;
}

.request-form__check {
  position: relative;
  margin-bottom: 40px;
  padding-left: 39px;
  font-size: 14px;
}

.request-form__check label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid var(--color-blue-600);
  border-radius: 5px;
  width: 24px;
  height: 24px;
  background-color: #fff;
}

.request-form__check label::after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 19px;
  height: 19px;
  background-image: url("../img/icons/check-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
}

.request-form__check label:hover::before {
  border-color: var(--color-green-primary);
}

.request-form__check input:checked + label::before {
  background-color: var(--color-green-primary);
}

.request-form__check input:checked + label::after {
  opacity: 1;
}

.request-form__check input {
  position: absolute;
  top: 0;
  left: 10px;
  opacity: 0;
  width: 1px;
}

.form_wrapper {
  border-radius: 30px;
  min-height: 140px;
  display: flex;
  align-items: center!important;
  max-width: 800px;
}

.header .two-in-row__column.popup_title_wrapper {
  align-items: center;
}

.form_info_message {
    color: #fff;
    font-size: 22px;
  padding: 20px;
}

.jGrowl {
  display: none!important;
}

.slider-section {
  overflow: hidden;
}

.card-slider {
  overflow: visible;
}

.card-slider__wrapper {
  position: relative;
}

.card-slider__slide {
  min-height: 100%;
}

.card-slider__actions {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card-slider__btn {
  margin-left: 30px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}

.card-slider__prev,
.card-slider__next {
  position: relative;
  top: 0;
  bottom: auto;
  left: auto;
  right: auto;
  margin-top: 0;
}

.card-slider .swiper-pagination {
  display: none;
}

.card-rating {
  height: 36px;
  padding: 4px 24px;
  border-radius: var(--borad-30);
  background-color: #fff;
  display: inline-flex;
  align-items: center;
}

.card-rating.dark {
  background-color: var(--color-blue-800);
}

.rating-value {
  margin-left: 6px;
}

.publ-date {
  display: inline-block;
  margin-bottom: 25px;
  border-radius: var(--borad-20);
  padding: 7px 13px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 14px;
  line-height: 1.55;
  background-color: var(--color-blue-800);
}

.news-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: var(--borad-30);
  height: 424px;
  background-color: var(--color-white);
  overflow: hidden;
}

.news-card:hover .news-card__btn {
  opacity: 1;
  color: #fff;
}

.news-card__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 38px;
  height: 100%;
}

.news-card__title {
  margin-top: 0;
  margin-bottom: 20px;
}

.news-card__title a {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--color-blue-100);
}

.news-card__text p {
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-card__btn {
  opacity: 0;
  margin-top: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  background-color: var(--color-green-primary);
}

.news-card__btn:hover,
.news-card__btn:focus-visible {
  background-color: var(--color-btn-hover);
}

.news-card__image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%;
  border-radius: var(--borad-30);
  overflow: hidden;
}

.news-card__image a {
  display: block;
  width: 100%;
  height: 100%;
}

.news-card__image picture {
  height: 100%;
}

.news-card__image img {
  width: 100%;
  height: 100%;
  border-radius: var(--borad-30);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
}

.news-card__image img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.product-card {
  position: relative;
}

.product-card:hover .product-card__btn {
  opacity: 1;
}

.product-card__preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: var(--borad-30);
  padding: 80px 100px 120px;
}

.product-card__image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.product-card__image img {
  -webkit-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.product-card__rating {
  position: absolute;
  left: 25px;
  bottom: 25px;
}

.product-card__bottom {
  position: relative;
  padding: 0 10px;
}

.product-card__title a {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 15px;
  width: calc(100% - 60px);
  color: var(--color-blue-100);
}

.product-card__title a:hover {
  color: var(--color-blue-300);
}

.product-card__btn {
  position: absolute;
  right: 10px;
  top: 0;
  opacity: 0;
  margin-top: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  background-color: var(--color-green-primary);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.product-card__btn:hover {
  color: #fff;
  background-color: var(--color-btn-hover);
}

.branches__slide {
  overflow: hidden;
  border-radius: var(--borad-30);
}

.branches__slide::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 40px;
  width: 25px;
  height: 30px;
  color: var(--color-white);
}

.branches__slide:nth-child(1) {
  background-color: var(--color-blue-400);
}

.branches__slide:nth-child(1)::before {
  content: "01";
}

.branches__slide:nth-child(2) {
  background-color: #ABB2C0;
}

.branches__slide:nth-child(2)::before {
  content: "02";
}

.branches__slide:nth-child(3) {
  background-color: #4B6983;
}

.branches__slide:nth-child(3)::before {
  content: "03";
}

.branches__slide:nth-child(4) {
  background-color: #1D2F3F;
}

.branches__slide:nth-child(4)::before {
  content: "04";
}

.branches__slide:nth-child(5) {
  background-color: #CECECE;
}

.branches__slide:nth-child(5)::before {
  content: "05";
  color: var(--color-blue-100);
}

.branches__slide:nth-child(5) .branches-card__title {
  color: var(--color-blue-100);
}

.branches__slide:nth-child(6) {
  background-color: #BCBFC6;
}

.branches__slide:nth-child(6)::before {
  content: "06";
  color: var(--color-blue-100);
}

.branches__slide:nth-child(6) .branches-card__title {
  color: var(--color-blue-100);
}

.branches__slide:nth-child(7) {
  background-color: #ABB2C0;
}

.branches__slide:nth-child(7)::before {
  content: "07";
}

.branches__slide:nth-child(8) {
  background-color: var(--color-blue-400);
}

.branches__slide:nth-child(8)::before {
  content: "08";
}

.branches__slide:nth-child(9) {
  background-color: #424958;
}

.branches__slide:nth-child(9)::before {
  content: "09";
}

.branches__slide:nth-child(10) {
  background-color: #4B6983;
}

.branches__slide:nth-child(10)::before {
  content: "10";
}

.branches-card {
  position: relative;
  padding-top: 20px;
}

.branches-card__title {
  position: absolute;
  z-index: 1;
  top: 75px;
  left: 40px;
  max-width: 75%;
  color: var(--color-white);
}

.reviews-card {
  margin-bottom: 10px;
  border-radius: var(--borad-30);
  padding: 80px;
  background-color: var(--color-white);
}

.reviews-card__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 30px;
  color: var(--color-blue-100);
}

.reviews-card__user {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-bottom: 30px;
  padding-bottom: 120px;
}

.reviews-card__user-image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  margin-right: 30px;
  padding-left: 62px;
}

.reviews-card__user-image img {
  position: relative;
  z-index: 1;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.reviews-card__user-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #000;
  background-image: url("../img/icons/quotes-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.reviews-card__user-name {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
}

.reviews-card__rating {
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.reviews-card__rating img {
  width: 18px;
  height: 18px;
}

.reviews-card__title {
    max-width: 587px;
  margin-top: 0;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
}

.reviews-card__text p {
  margin-top: 0;
}

.reviews-card .two-in-row__column {
  margin-bottom: 0;
}
.btn-more {
  display: none!important;
}

.page-titlebox {
  padding: 0;
  position: relative;
  overflow: hidden;
  background-image: url("../img/catalog-hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page-titlebox::after {
  content: "";
  position: absolute;
  left: -55px;
  top: 0;
  border-radius: 50%;
  width: 670px;
  height: 670px;
  background: rgba(139, 190, 92, 0.75);
  -webkit-filter: blur(250px);
  filter: blur(250px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.page-titlebox__inner {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 500px;
  background-color: rgba(25, 36, 57, 0.75);
}

.accord:not(:last-child) {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-blue-600);
  padding-bottom: 10px;
}

.accord__title {
  position: relative;
  margin-bottom: 30px;
  padding-right: 60px;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: var(--color-blue-100);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.accord__title::after {
  content: "";
  position: absolute;
  right: 0;
  top: -7px;
  border: 1px solid var(--color-blue-500);
  border-radius: 50%;
  padding: 6px;
  width: 45px;
  height: 45px;
  background: url("../img/icons/plus.svg") no-repeat center/auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.accord__title.active::after {
  background: url("../img/icons/minus.svg") no-repeat center/auto;
  border-color: currentColor;
}

.accord__title:hover::after,
.accord__title:focus-visible::after {
  border-color: currentColor;
}

.accord__text {
  display: none;
}

.accord__text p {
  margin-top: 0;
  margin-bottom: 20px;
}

.hero {
  position: relative;
  padding-top: 100px;
  background-color: #9ca1ad;
  background-image: url("../img/first-screen-3640х1960.webp");
  background-repeat: no-repeat;
  background-size: contain;
}

.hero__inner {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hero__content {
  margin-right: 30px;
  max-width: 940px;
}

.hero__title {
  position: relative;
  z-index: 1;
}

.hero__descr {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  max-width: 400px;
  color: #fff;
}

.hero-special-box {
  padding: 0 15px;
  background-color: #fff;
}

.about-section {
  --slider-padding: 80px;
  overflow: hidden;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(35%, var(--color-blue-100)), color-stop(35%, var(--color-white)), to(var(--color-white)));
  background-image: -o-linear-gradient(top, var(--color-blue-100) 35%, var(--color-white) 35%, var(--color-white) 100%);
  background-image: linear-gradient(to bottom, var(--color-blue-100) 35%, var(--color-white) 35%, var(--color-white) 100%);
}

.about-section__content p {
  margin-top: 0;
}

.about-section__image picture {
  height: 100%;
}

.about-section__image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--borad-30);
}

.about-section__slider {
  border-radius: var(--borad-30);
  padding: var(--slider-padding);
  width: 100%;
  height: auto;
  background-color: var(--color-blue-400);
  background-image: url("../img/round-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 35%;
}

.about-section__slider-title {
  margin-top: 30px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  color: var(--color-white);
}

.about-section__slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.about-section__slider-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-section__slider-content p {
  max-width: 47%;
  color: var(--color-white);
}

.about-section__slider-btn {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: auto;
}

.about-section__prev {
  left: auto;
  right: calc(var(--slider-padding) + 45px);
  bottom: var(--slider-padding);
}

.about-section__next {
  right: calc(var(--slider-padding));
  bottom: var(--slider-padding);
}

.about-section__progress-bar {
  --time: 12s;
  position: absolute;
  top: var(--slider-padding);
  left: var(--slider-padding);
  width: 10%;
  height: 3px;
  border-radius: 3px;
  overflow: hidden;
  background-color: var(--color-white-transparent);
}

.about-section__progress-bar .about-section__progress {
  width: 0;
  height: 100%;
  -webkit-animation: width100 var(--time) infinite linear;
  animation: width100 var(--time) infinite linear;
}

.about-section__progress-bar .about-section__progress.restarted {
  -webkit-animation: width100 var(--time) infinite linear;
  animation: width100 var(--time) infinite linear;
}

@-webkit-keyframes width100 {
  0% {
    width: 0;
    background-color: var(--colorbar);
  }

  100% {
    width: 100%;
    background-color: var(--colorbar);
  }
}

@keyframes width100 {
  0% {
    width: 0;
    background-color: var(--colorbar);
  }

  100% {
    width: 100%;
    background-color: var(--colorbar);
  }
}

.about-section .swiper-pagination-progressbar {
  top: calc(var(--slider-padding) - 3px);
  left: var(--slider-padding);
  border-radius: 15px;
  width: 10%;
  height: 3px;
  background-color: var(--color-white-transparent);
}

.about-section .swiper-pagination-progressbar-fill {
  background: #fff;
  border-radius: 15px;
}

.about-section .swiper-scrollbar {
  left: var(--slider-padding);
  top: var(--slider-padding);
  bottom: auto;
  width: 10%;
  height: 3px;
  background-color: var(--color-white-transparent);
}

.about-section .swiper-scrollbar-drag {
  background-color: var(--color-white);
}

.about-section .swiper-pagination {
  bottom: 40px;
}

.about-section .swiper-pagination-fraction {
  left: auto;
  right: calc(var(--slider-padding) + 100px + 40px);
  bottom: calc(var(--slider-padding) + 5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #fff;
}

/* about - pages */

.about__title {
  max-width: 1500px;
}

.about__descr {
  
}

.about__descr p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  padding: 0 10px;
}

.worth {
  margin-top: -120px;
  padding-top: 270px;
  background-image: url("../img/round-bg-left-white.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 20%;
}

.worth__slider-wrapper {
  position: relative;
}

.worth__title-box {
  position: relative;
  z-index: 2;
  width: 38%;
  max-width: 430px;
}

.worth__slider {
  margin-top: -160px;
}

.worth__slide-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.worth__slide-text {
  padding-top: 170px;
  padding-bottom: 45px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 37%;
  flex: 1 0 37%;
  max-width: 430px;
  color: var(--color-blue-400);
}

.worth__slide-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 60%;
  flex: 0 1 60%;
  position: relative;
  margin-left: 80px;
}

.worth__slide-image img {
  border-radius: var(--borad-30);
  height: 660px;
  width: 100%;
}

.worth__slide-title {
  position: absolute;
  bottom: 50px;
  left: -60px;
  z-index: 1;
  font-weight: 600;
  font-size: 105px;
  line-height: 1.05;
  color: var(--color-white);
}

.worth__prev,
.worth__next {
  bottom: 10px;
}

.worth__prev {
  left: 0;
}

.worth__next {
  left: 55px;
}

.worth__counter-current {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
}

.worth__bullets {
  display: none;
  height: 30px;
  padding: 20px;
}

.worth__bullet {
  background-color: #fff;
  opacity: 0.7;
  margin-right: 10px;
}

.worth__bullet-active {
  opacity: 1;
}

.worth .swiper-pagination-fraction {
  position: static;
  padding-left: 15px;
  text-align: left;
  color: #fff;
}

.worth .swiper-pagination-fraction {
  position: static;
  padding-left: 15px;
  text-align: left;
  color: #fff;
}

.worth .swiper-pagination-current {
  padding-right: 10px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  opacity: 1;
}

.worth .swiper-pagination-total {
  opacity: 0.5;
}

.about-director {
  padding: 0;
}

.about-director__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 87vw;
}

.about-director__image {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: calc(107px + 973 * (100vw - 320px) / 1600);
  flex-basis: calc(107px + 973 * (100vw - 320px) / 1600);
  margin-right: calc(20px + 80 * (100vw - 320px) / 1600);
}

.about-director__image picture {
  height: calc(450px + 630 * (100vw - 320px) / 1600);
}

.about-director__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-director__info {
  position: absolute;
  z-index: 1;
  top: calc(16px + 34 * (100vw - 320px) / 1600);
  right: calc(22px + 28 * (100vw - 320px) / 1600);
  width: 50%;
  text-align: right;
}

.about-director__title {
  font-weight: 600;
  font-size: calc(14px + 16 * (100vw - 320px) / 1600);
  line-height: 1.4;
  color: var(--color-blue-100);
}

.about-director__descr {
  font-size: calc(12px + 6 * (100vw - 320px) / 1600);
  color: var(--color-blue-400);
}

.about-director__text {
  position: relative;
  padding-top: 140px;
  padding-top: calc(80px + 60 * (100vw - 320px) / 1600);
  max-width: 500px;
}

.about-director__text::before {
  content: "";
  position: absolute;
  left: 0;
  top: -50px;
  width: 125px;
  height: 100px;
  background-image: url("../img/icons/quotes-green.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.certificate__slider {
  padding-bottom: 0;
}

.certificate__image {
  border: 1px solid var(--color-blue-600);
  border-radius: var(--borad-30);
  padding: 40px;
}

.certificate__image img {
  -webkit-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.certificate__image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.certificate__descr {
  color: var(--color-blue-100);
  padding: 15px;
}

.certificate__descr p {
  margin-top: 0;
  margin-bottom: 5px;
}

.about-common__inner:not(:last-child) {
  padding-bottom: 150px;
}

.about-common__inner.revers {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.about-common__inner.revers .about-common__content {
  padding-left: 0;
  padding-right: 100px;
}

.about-common__image picture {
  height: 100%;
}

.about-common__image img {
  height: 100%;
  min-height: 660px;
  border-radius: var(--borad-30);
}

.about-common__content {
  padding-top: 50px;
  padding-left: 100px;
}

.about-common__content p {
  margin-bottom: 20px;
}

.about-common__content a {
  color: var(--color-green-primary);
}

.about-common__content a:hover,
.about-common__content a:focus-visible {
  color: var(--color-btn-hover);
}

.about-common__content strong,
.about-common__content b {
  color: var(--color-blue-100);
}

.about-triple {
  margin-top: 300px;
  padding-top: 1px;
  background-color: var(--color-blue-400);
  background-image: url("../img/round-bg-left-white.svg"), url("../img/round-bg-right-up-white.svg");
  background-repeat: no-repeat;
  background-position: left bottom, right top;
  background-size: 15%, 25%;
}

.about-triple__image {
  margin-top: -150px;
  margin-bottom: 100px;
}

.about-triple__image picture {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-triple__image img {
  border-radius: var(--borad-30);
  width: 100%;
  height: 100%;
  max-height: 660px;
}

.about-triple__text {
  color: var(--color-white);
}

/* ----- */

.advantages {
  position: relative;
  overflow-y: hidden;
  padding-bottom: 0;
}

.advantages__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85%;
}

.advantages__bg picture,
.advantages__bg img {
  height: 100%;
  width: 100%;
}

.advantages__inner {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.advantages__title {
  border: none !important;
  padding: 0;
  max-width: 800px;
}

.advantages__thumbs-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 80px;
  margin-top: 40px;
  margin-left: auto;
  border-radius: var(--borad-30);
  padding: 75px;
  max-width: 1080px;
  height: 562px;
  color: #fff;
  background-color: var(--color-green-primary);
  background-image: url("../img/round-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 35%;
}

.advantages-slider {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
}

.advantages-slider__title {
  margin-top: 0;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
}

.advantages-slider__pagination.swiper-pagination-bullets {
  display: none;
}

.advantages-slider__pagination {
  display: none;
}

.advantages-slider__prev {
  left: auto;
  right: 45px;
  bottom: 0;
}

.advantages-slider__next {
  right: 0;
  bottom: 0;
}

.advantages-thumb-slider {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
  margin: -35px 0;
  width: 40%;
  height: 100%;
}

.advantages-thumb-slider__title {
  font-weight: 600;
  line-height: 1.3;
}

.advantages-thumb-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 65px;
  padding-top: 35px;
  padding-bottom: 35px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  cursor: pointer;
}

.advantages-thumb-slide::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid var(--color-white-transparent);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  -webkit-transition: color 0.6s, background-color 0.6s;
  -o-transition: color 0.6s, background-color 0.6s;
  transition: color 0.6s, background-color 0.6s;
  pointer-events: none;
}

.advantages-thumb-slide:nth-child(1)::before {
  content: "1";
}

.advantages-thumb-slide:nth-child(2)::before {
  content: "2";
}

.advantages-thumb-slide:nth-child(3)::before {
  content: "3";
}

.advantages-thumb-slide:nth-child(4)::before {
  content: "4";
}

.advantages-thumb-slide:not(:last-child) {
  border-bottom: 1px solid var(--color-white-transparent);
}

.advantages-thumb-slide.swiper-slide-thumb-active {
  z-index: 1;
}

.advantages-thumb-slide.swiper-slide-thumb-active::before {
  color: var(--color-blue-100);
  background-color: #fff;
}

.partners__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.partners__inner picture {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.branches__title {
  position: relative;
}

.branches__descr {
  max-width: 800px;
  margin: 50px 0;
}

.catalog__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -50px;
}

.catalog .product-card {
  margin-left: 10px;
  margin-bottom: 40px;
  width: calc(33.3333333333% - 10px);
}

.product__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product__content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding-left: 80px;
}

.product__rating {
  margin-bottom: 40px;
}

.product__title {
  margin-top: 0;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-blue-600);
  padding-bottom: 25px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  color: var(--color-blue-100);
}

a.btn_one_click {
  border: none!important;
}

.product__descr {
  margin-bottom: 40px;
}

.product__descr p {
  margin-top: 0;
}

.product__btn {
  margin-bottom: 40px;
}

.product__thumbs-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  gap: 10px;
  width: 50%;
  max-height: 740px;
}

.product-slider {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  border-radius: var(--borad-30);
  background-color: var(--color-blue-700);
  padding: 100px 60px;
}

.product-slider__slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-slider__slide picture {
  height: 100%;
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  text-align: center;
}

.product-slider__slide picture:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.product-slider__image {
  height: 100%;
}

.product-thumb-slider {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.product-thumb-slide {
    cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: var(--borad-20);
  background-color: var(--color-blue-700);
  padding: 26px 18px;
}

.product-thumb-slide:hover {
  border-color: var(--color-blue-500);
}

.product-thumb-slide img {
  max-width: 70px;
  max-height: 70px;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
}

.product-thumb-slide img:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.product-thumb-slide.swiper-slide-thumb-active {
  border-color: var(--color-blue-500);
}

.news__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
}

.news .news-card {
  margin-left: 10px;
  margin-bottom: 10px;
  width: calc(50% - 10px);
}

.article-banner {
  padding: 0;
}

#pdopage .pagination {
  display: none;
}

.article__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 150px;
}

.article__aside {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 28%;
  flex: 0 0 28%;
  max-width: 440px;
}

.article__content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 68%;
  flex: 0 0 68%;
  padding-bottom: 30px;
}

.article__content p {
  margin-bottom: 30px;
}

.article__content strong {
  color: var(--color-blue-100);
}

.article__content img {
  border-radius: var(--borad-30);
  margin: 15px 0 30px;
}

.article__content ul {
  margin-top: -15px;
}

.article__content ul li {
  margin-bottom: 15px;
}

.contacts {
  padding-top: 100px;
}

.contacts__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.contacts__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 34%;
  flex: 0 0 34%;
}

.contacts__block {
  border-radius: var(--borad-30);
  padding: 50px 50px 25px;
  margin-bottom: 10px;
}

.contacts .icon-block {
  margin-bottom: 24px;
  color: var(--color-blue-100);
}

.contacts .icon-block p {
  margin: 0;
}

.contacts .icon-block span:not(.work-time) {
  color: var(--color-blue-100);
}

.contacts__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contacts__social .icon-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 0;
}

.contacts__social .icon-block:hover a,
.contacts__social .icon-block:focus-visible a {
  color: var(--color-blue-100);
}

.contacts__map {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  overflow: hidden;
  border-radius: var(--borad-30);
  background-image: url("../img/map-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contacts__map iframe,
.contacts__map > ymaps {
  width: 100%;
  min-height: 100%;
}

.error-section__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.error-section__title {
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 70px;
  line-height: 1.2;
  color: var(--color-blue-100);
}

.error-section__text {
  margin-bottom: 50px;
  color: var(--color-blue-300);
}

.scroll_up {
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  background-color: #7ab929;
  z-index: -1;
  opacity: 0;
  transition: all ease 0.6s;
  cursor: pointer;
  &.active {
    z-index: 10;
    opacity: 1;  
  }
  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -32%) rotate(-135deg);
  }
}

#oneClick_body {
  padding: 50px 60px 60px;
  .product-name {
    color: var(--color-blue-100);
    font-size: calc(14px + 12 * (100vw - 320px) / 1280);
    margin-bottom: calc(20px + 16 * (100vw - 320px) / 1280);
    font-weight: bold;
  }
  .msoc_product_line_pagetitle {
    margin-left: 0;
  }
  .msoc_product_line_count {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .product__add-cart {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  input.forder-popup__input,
  textarea.forder-popup__textarea {
    width: 100%;
  }
  .input-group.input-prepend {
    display: flex;
    column-gap: 10px;
  }
  input[type="number"] {
    width: 50px;
  }
  .modal-footer {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .forder-popup__block--grey {
    padding: 12px 36px;
  }
}

@media (min-width: 992.01px) {

  .header__mob-btn {
    position: fixed;
    left: 0;
    top: 0;
    width: 5.3vw;
    height: 100vh;
    z-index: 1000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    color: #fff;
    background-color: var(--color-blue-primary);
  }

  .header__mob-btn-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: relative;
    left: 0;
    border: 1px solid var(--color-blue-600);
    border-radius: 50px;
    padding: 2px 30px 2px 20px;
  }

  .header__mob-btn-inner .line {
    stroke: #fff;
  }

  .header__mob-btn-inner span {
    margin-left: 10px;
  }
}

@media (min-width: 1920.1px) {
  body {
    font-size: 0.9vw;
  }

  .title-h1 {
    font-size: 5vw;
  }

  .title-h2 {
    font-size: 3vw;
  }

  .title-h3 {
    font-size: 2.5vw;
  }

  .title-h4 {
    font-size: 2vw;
  }

  .title-h5 {
    font-size: 1.3vw;
  }

  .btn {
    font-size: 0.7vw;
  }

  .breadcrumbs__list li {
    padding-right: 40px;
  }

  .breadcrumbs__list li:not(:last-child)::after {
    width: 0.9vw;
    height: 0.9vw;
  }

  .breadcrumbs__list a,
  .breadcrumbs__list span {
    font-size: 0.9vw;
  }

  .icon-block {
    font-size: 0.9vw;
  }

  .icon-block__info span {
    font-size: 0.7vw;
  }

  .request-form__check {
    font-size: 0.8vw;
  }

  .reviews-card__user-name {
    font-size: 1vw;
  }

  .hero {
    padding-top: 150px;
  }

  .hero__descr {
    max-width: 25vw;
  }

  .about-section .swiper-scrollbar {
    height: 0.2vw;
  }

  .advantages__thumbs-wrapper {
    padding: 3vw;
    height: 25vw;
    max-width: 55vw;
  }

  .product-slider__image img {
    height: 100%;
  }

  .contacts .icon-block span:not(.work-time) {
    font-size: 0.9vw;
  }

  .popup-request.popup .popup__content {
    justify-content: center;
  }
 
  .popup_title_wrapper {
    width: 330px;
  }

}

@media (min-width: 1920.1px) and (min-width: 1920.01px) {
    .swiper-button-prev {
        margin-right: 30px;
    }
  .btn {
    height: 2.3vw;
    padding: 0 50px;
  }
}

@media (min-width: 1920.01px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 2.3vw;
    height: 2.3vw;
  }

  .icon-block__icon {
    padding: 20px;
  }

  .icon-block__icon svg {
    width: 22px;
    height: 22px;
  }

  .header__social img {
    width: 2.1vw;
    height: 2.1vw;
  }

  .header__mob-btn-inner {
    height: 2.3vw;
    padding: 5px 50px;
  }

  .header__address .icon-block__info {
    font-size: 0.8vw;
  }

  .header__nav-list > li {
    font-size: 32px;
  }

  .header__produce-title {
    margin-bottom: 0.8em;
    font-size: 32px;
  }

  .header__produce-list li {
    margin-bottom: 1.4em;
  }

  .header__produce-list a {
    font-size: 16px;
  }

  .request-form {
    max-width: 30%;
  }

  .news-card__btn {
    width: 2.5vw;
    height: 2.5vw;
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .product-card__bottom {
    padding: 0 15px;
  }

  .product-card__btn {
    width: 2.5vw;
    height: 2.5vw;
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .branches-card__image img {
    width: 100%;
  }

  .worth__slide-text {
    padding-bottom: 2.5vw;
  }

  .worth__next {
    left: 3vw;
  }

  .advantages-slider__prev {
    right: 2.3vw;
  }

  .advantages-thumb-slide {
    padding-left: 3vw;
  }

  .advantages-thumb-slide::before {
    width: 2.3vw;
    height: 2.3vw;
  }
  
  .object .product__title {
      font-size: 50px;
  }
}

@media (max-width: 1900px) {
  .product-thumb-slide {
    padding-top: calc(10px + 16 * (100vw - 320px) / 1580);
    padding-bottom: calc(10px + 16 * (100vw - 320px) / 1580);
    padding-left: calc(5px + 12 * (100vw - 320px) / 1580);
    padding-right: calc(5px + 12 * (100vw - 320px) / 1580);
  }
}

@media (max-width: 1800px) {
  .title-h1 {
    font-size: calc(16px + 79 * (100vw - 320px) / 1600);
  }

  .popup-request.popup .request-form {
    margin-left: auto;
  }
  
  .object .product__title {
      font-size: 40px;
  }
}

@media (max-width: 1600px) {
  body {
    font-size: calc(14px + 2 * (100vw - 320px) / 1330);
  }

  .title {
    margin-bottom: calc(22px + 8 * (100vw - 320px) / 1280);
  }

  .title-h2 {
    font-size: calc(22px + 31 * (100vw - 320px) / 1330);
  }

  .title-h3 {
    font-size: calc(22px + 10 * (100vw - 320px) / 1330);
  }

  .title-h4 {
    font-size: calc(17px + 9 * (100vw - 320px) / 1330);
  }

  .title-h5 {
    font-size: calc(15px + 4 * (100vw - 320px) / 1330);
  }

  section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .btn {
    padding: 0 25px;
    height: 40px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
  }

  .popup-request.popup .popup__close {
    width: 40px;
    height: 40px;
  }

  .popup-request.popup .popup__logo picture {
    max-width: calc(190px + 120 * (100vw - 320px) / 1330);
  }

  .breadcrumbs__list li:not(:last-child)::after {
    top: 5px;
  }

  .about-progress__item {
    padding: 30px 35px;
    min-height: 206px;
  }

  .icon-block__icon {
    padding: 9px;
    margin-right: 10px;
  }

  .header__social img {
    width: 40px;
    height: 40px;
  }

  .header__menu {
    padding: 50px 5vw 0;
  }

  .header__menu-bottom {
    padding-top: calc(30px + 60 * (100vw - 320px) / 1330);
    padding-bottom: calc(30px + 60 * (100vw - 320px) / 1330);
  }

  .header__produce-list a {
    font-size: 14px;
  }

  .logo__link {
    width: calc(98px + 98 * (100vw - 320px) / 1330);
  }

  .footer__row:first-child {
    margin-bottom: calc(30px + 20 * (100vw - 320px) / 1330);
    padding-bottom: calc(30px + 20 * (100vw - 320px) / 1330);
  }

  .footer__social img {
    width: 40px;
    height: 40px;
  }

  .request-form__title {
    font-size: calc(14px + 12 * (100vw - 320px) / 1280);
    margin-bottom: calc(20px + 16 * (100vw - 320px) / 1280);
  }

  .request-form__field {
    margin-bottom: 25px;
    font-size: calc(14px + 4 * (100vw - 320px) / 1330);
  }

  .request-form__file {
    margin-bottom: 25px;
  }

  .request-form__check {
    margin-bottom: 25px;
  }

  .card-slider__btn {
    margin-left: 25px;
  }

  .news-card__content {
    padding: 25px;
  }

  .news-card__title {
    margin-bottom: 15px;
  }

  .news-card__text {
    font-size: calc(14px + 4 * (100vw - 320px) / 1330);
  }

  .product-card__preview {
    padding-top: calc(37px + 33 * (100vw - 320px) / 1330);
    padding-bottom: calc(54px + 56 * (100vw - 320px) / 1330);
    padding-left: calc(42px + 48 * (100vw - 320px) / 1330);
    padding-right: calc(42px + 48 * (100vw - 320px) / 1330);
  }

  .product-card__rating {
    left: 20px;
    bottom: 20px;
  }

  .branches__slide::before {
    font-size: calc(14px + 3 * (100vw - 320px) / 1330);
    top: calc(10px + 25 * (100vw - 320px) / 1330);
    left: calc(10px + 25 * (100vw - 320px) / 1330);
  }

  .branches-card__title {
    left: calc(5px + 30 * (100vw - 320px) / 1330);
    top: 55px;
  }

  .reviews-card__user-image img {
    width: 75px;
    height: 75px;
  }

  .reviews-card__user-name {
    font-size: calc(14px + 8 * (100vw - 320px) / 1330);
  }

  .reviews-card__title {
    font-size: calc(17px + 23 * (100vw - 320px) / 1330);
  }

  .page-titlebox::after {
    width: calc(230px + 310 * (100vw - 320px) / 1280);
    height: calc(230px + 310 * (100vw - 320px) / 1280);
  }

  .page-titlebox__inner {
    height: calc(200px + 270 * (100vw - 320px) / 1280);
  }

  .accord__title {
    font-size: calc(15px + 7 * (100vw - 320px) / 1280);
  }

  .hero {
    background-image: url("../img/first-screen-1325х825.webp");
    padding-top: calc(50px + 30 * (100vw - 320px) / 1330);
  }

  .hero__descr {
    margin-bottom: 30px;
  }

  .about-section {
    --slider-padding: 50px;
  }

  .about-section__prev {
    right: calc(var(--slider-padding) + 40px);
  }

  .about-section .swiper-pagination-fraction {
    right: calc(var(--slider-padding) + 90px + 40px);
  }

  .worth__slide-image img {
    height: 550px;
  }

  .worth__slide-title {
    font-size: calc(24px + 46 * (100vw - 320px) / 1280);
    left: -50px;
  }

  .worth__next {
    left: 50px;
  }

  .worth__counter-current {
    font-size: 30px;
  }

  .worth .swiper-pagination-current {
    font-size: calc(20px + 15 * (100vw - 320px) / 1330);
  }

  .about-director__text::before {
    top: -35px;
    width: 92px;
    height: 74px;
  }

  .certificate__image {
    padding: 30px;
  }

  .about-common__inner:not(:last-child) {
    padding-bottom: calc(30px + 100 * (100vw - 320px) / 1330);
  }

  .about-common__inner.revers .about-common__content {
    padding-left: 0;
    padding-right: 75px;
  }

  .about-common__image img {
    min-height: 480px;
  }

  .about-common__content {
    padding-top: 30px;
    padding-left: 75px;
  }

  .about-common__content {
    padding-left: 20px;
  }

  .about-triple {
    margin-top: 250px;
    background-size: 15%, 30%;
  }

  .about-triple {
    margin-top: 220px;
  }

  .about-triple__image {
    margin-top: -110px;
    margin-bottom: 75px;
  }

  .advantages__thumbs-wrapper {
    gap: 50px;
    padding: 50px;
    height: 485px;
  }

  .advantages-slider__title {
    font-size: calc(20px + 16 * (100vw - 320px) / 1330);
  }

  .advantages-slider__prev {
    right: 40px;
  }

  .advantages-thumb-slider {
    margin: -20px 0;
    height: 320px;
  }

  .advantages-thumb-slide {
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .advantages-thumb-slide::before {
    width: 40px;
    height: 40px;
  }

  .branches__descr {
    margin: 40px 0;
  }

  .product__rating {
    margin-bottom: calc(15px + 20 * (100vw - 320px) / 1280);
  }

  .product__title {
    font-size: calc(20px + 16 * (100vw - 320px) / 1280);
  }

  .article__inner {
    padding-top: calc(30px + 100 * (100vw - 320px) / 1330);
    padding-bottom: calc(30px + 100 * (100vw - 320px) / 1330);
  }

  .contacts {
    padding-top: calc(30px + 50 * (100vw - 320px) / 1330);
    padding-bottom: calc(30px + 50 * (100vw - 320px) / 1330);
  }

  .contacts__block {
    padding: 28px 28px 5px;
  }

  .contacts .icon-block {
    font-size: 16px;
  }

  .error-section__title {
    font-size: calc(22px + 48 * (100vw - 320px) / 1280);
  }
}

@media (max-width: 1440px) {
  .title-h1 {
    margin-bottom: 30px;
  }
  
  .object .product__title {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .more-btn.icon-block {
    margin: 50px auto 0;
  }

  .icon-block {
    margin-right: 30px;
  }

  .header__inner {
    padding: 13px 15px;
    min-height: 75px;
  }

  .header__social {
    margin-right: 20px;
    padding-right: 20px;
  }

  .header__menu {
    top: 75px;
    height: calc(100% - 75px);
  }

  .header__produce-list li {
    margin-bottom: 21px;
  }

  .footer__address {
    max-width: 370px;
  }

  .request-form {
    padding: 35px 40px 40px;
    max-width: 386px;
  }

  .request-form__title {
    font-size: calc(17px + 5 * (100vw - 320px) / 1120);
  }

  .publ-date {
    margin-bottom: 15px;
    padding: 3px 10px;
    font-size: calc(12px + 2 * (100vw - 320px) / 1330);
  }

  .reviews-card {
    padding: 50px;
  }

  .reviews-card__user {
    padding-bottom: 80px;
  }

  .reviews-card__user-image {
    margin-right: 25px;
  }

  .reviews-card__user-image::before {
    width: 75px;
    height: 75px;
  }

  .accord__title::after {
    width: 40px;
    height: 40px;
  }

  .product__content {
    padding-left: 40px;
  }

  .product__thumbs-gallery {
    max-height: 530px;
  }

  .product-thumb-slide img {
    max-width: 50px;
    max-height: 50px;
  }

  .popup-request.popup .popup__body {
    padding: 40px 75px;
  }

  .popup-request.popup .popup__close {
    top: 0;
    right: -40px
  }
}

@media (max-width: 1400px) {
  .card-rating {
    width: 145px;
    height: 30px;
  }

  .about-section {
    --slider-padding: 40px;
  }

  .about-section__slider-content {
    display: block;
  }

  .about-section__slider-content p {
    max-width: none;
  }

  .contacts__info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
  }
}

@media (max-width: 1340px) {
  .logo__descr {
    display: none;
  }
}

@media (max-width: 1300px) {
  .about-progress__item {
    padding: 25px 30px;
  }

  .worth__title-box {
    width: 100%;
    max-width: none;
  }

  .worth__slider {
    margin-top: 0;
    padding-bottom: 60px;
    margin-bottom: -30px;
  }

  .worth__slide-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .worth__slide-text {
    padding-top: 0;
    padding-bottom: 10px;
    max-width: 100%;
  }

  .worth__slide-image {
    margin-left: auto;
    margin-right: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
  }

  .worth__prev,
  .worth__next {
    display: none;
  }

  .worth__bullets {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: -30px;
  }

  .about-common__inner.revers .about-common__content {
    padding-right: 20px;
  }
}

@media (max-width: 1200px) {
  .title-h1 {
    font-size: calc(24px + 36 * (100vw - 320px) / 880);
  }

  .title-h2 {
    font-size: calc(22px + 24 * (100vw - 320px) / 880);
  }

  .title-h3 {
    font-size: calc(20px + 4 * (100vw - 320px) / 880);
  }

  .three-in-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .three-in-row__column {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }

  .footer__inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 70px 0;
  }

  .footer__column {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 35%;
    flex: 1 0 35%;
  }

  .footer__column:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }

  .footer__column:not(:last-child) {
    margin-bottom: 40px;
  }

  .footer__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .footer__list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
  }

  .card-slider__actions {
    top: 15px;
  }

  .card-rating {
    height: 25px;
  }

  .news-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .news-card__image img {
    max-height: 300px;
  }

  .branches-card__title {
    top: 50px;
  }

  .about-section__slider-title {
    font-size: calc(20px + 20 * (100vw - 320px) / 880);
  }

  .about-section__slider-content {
    font-size: calc(14px + 4 * (100vw - 320px) / 880);
  }

  .worth {
    margin-top: -100px;
    padding-top: calc(120px + 80 * (100vw - 320px) / 880);
  }

  .about-director__text::before {
    top: -23px;
    width: 60px;
    height: 48px;
  }

  .certificate__image {
    padding: 20px;
  }

  .about-triple__image {
    margin-bottom: 40px;
  }

  .product__thumbs-gallery {
    max-height: 400px;
  }
}
@media (max-width: 1100px) {
    .hero {
        background-image: url(../img/first-screen-1325х825.webp);
        background-position: bottom;
    }
}
@media (max-width: 1024px) {
  .worth__slider {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  body {
    margin-left: 0;
    width: 100%;
  }

  body.lock.header-menu-mod {
    margin-left: 0;
    width: 100%;
  }

  .container {
    max-width: 100%;
    padding: 0 40px;
  }

  .title-h4 {
    line-height: 1.3;
  }

  .about-progress__item {
    padding: calc(20px + 5 * (100vw - 320px) / 1280);
    min-height: 180px;
  }

  .header__mob-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 20px;
    border: 1px solid var(--color-blue-600);
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }

  .header__mob-btn-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid var(--color-blue-600);
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }

  .header__mob-btn-inner span {
    display: none;
  }

  .header__menu {
    left: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 100%;
  }

  .card-slider__actions {
    top: 10px;
  }

  .product-card__rating {
    left: 10px;
    bottom: 10px;
  }

  .reviews-card__title {
    margin-bottom: 5px;
  }

  .about__descr p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .worth__slide-image {
    margin-left: 80px;
    margin-right: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin-left: calc(25px + 35 * (100vw - 320px) / 1330);
  }

  .worth__slide-title {
    left: -30px;
  }

  .worth__counter-current {
    font-size: 22px;
  }

  .about-director__inner {
    width: 100%;
    max-width: 100%;
  }

  .about-triple {
    margin-top: 180px;
    background-size: 20%, 40%;
  }
  
  .header__produce-title, .header__nav-list > li {
    font-size: calc(26px + 2 * (100vw - 420px) / 600);
  }

}

@media (max-width: 920px) {
  .title-h1 {
    margin-bottom: 20px;
  }

  .two-in-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .two-in-row__column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 100%;
  }

  .more-btn.icon-block {
    margin: 30px auto 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .popup-request.popup .popup__close {
    right: 15px;
  }

  .popup-request.popup .popup__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .breadcrumbs__list li:not(:last-child)::after {
    top: 4px;
  }

  .header__social {
    border: none;
    padding-right: 0;
  }

  .header__menu-bottom .icon-block {
    margin-bottom: 50px;
  }

  .header__menu-bottom .header__social {
    margin-bottom: 50px;
  }

  .header__address {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .request-form__field {
    margin-bottom: 30px;
  }

  .request-form__file {
    margin-bottom: 30px;
  }

  .request-form__check {
    margin-bottom: 30px;
  }

  .card-slider {
    padding-bottom: 50px;
  }

  .card-slider .swiper-pagination {
    display: block;
  }

  .news-card__btn {
    display: none;
  }

  .news-card__image img {
    max-height: 200px;
  }

  .product-card__preview {
    border-radius: var(--borad-20);
  }

  .branches-card__title {
    top: 45px;
  }

  .reviews-card {
    border-radius: var(--borad-20);
    padding: 40px;
  }

  .reviews-card__main {
    padding-right: 0;
  }

  .reviews-card__user {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  .reviews-card__user-image {
    margin-right: 20px;
    padding-left: 42px;
  }

  .reviews-card__user-image img {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  .reviews-card__user-image::before {
    width: 50px;
    height: 50px;
  }

  .reviews-card__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .reviews-card__user-name {
    width: 100%;
  }

  .reviews-card__rating {
    position: static;
  }

  .reviews-card .publ-date {
    margin-bottom: 0;
  }

  .about-section__content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .about-section__content .two-in-row__column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .about-section__image {
    width: 100%;
    max-height: 400px;
  }

  .about-section__image img {
    width: 100%;
  }

  .about-section__slider {
    padding-bottom: 100px;
  }

  .about-section__prev {
    display: none;
  }

  .about-section__next {
    display: none;
  }

  .worth__slide-image img {
    height: 440px;
  }

  .certificate__image {
    border-radius: var(--borad-20);
    padding: 15px;
  }

  .about-common__inner:not(:last-child) {
    padding-bottom: 30px;
  }

  .about-common__inner.revers .about-common__content {
    padding-right: 0;
  }

  .about-common__image img {
    min-height: 400px;
  }

  .about-common__content {
    padding-top: 0;
    padding-left: 0;
  }

  .advantages__thumbs-wrapper {
    gap: 40px;
    padding: 40px;
    height: 447px;
  }

  .advantages-slider__pagination.swiper-pagination-bullets {
    display: block;
    bottom: 40px;
  }

  .advantages-slider__pagination {
    display: block;
  }

  .branches__descr {
    display: none;
  }

  .product__inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .product__content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-left: 0;
  }

  .product__thumbs-gallery {
    margin-bottom: 50px;
    width: 100%;
    max-width: 740px;
  }

  .product-slider {
    padding: 40px;
    margin-bottom: 10px;
  }

  .product-slider__slide img {
    width: auto;
    height: 100%;
  }

  .product-thumb-slide {
    border-radius: 15px;
  }

  .product-thumb-slide img {
    max-width: 40px;
    max-height: 40px;
  }

  .article__inner {
    display: block;
  }

  .article__aside {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .article__content {
    border-bottom: 1px solid var(--color-blue-600);
  }

  .article__content ul li {
    margin-bottom: 10px;
  }

  .article__btn {
    margin-top: 30px;
  }

  .contacts__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contacts__info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .contacts__map {
    max-height: 350px;
  }

  .contacts__map iframe,
  .contacts__map > ymaps {
    min-height: 300px;
  }

  .header .two-in-row__column.popup_title_wrapper.popup_title_wrapper, .header .two-in-row__column.popup_title_wrapper.form_wrapper {
    flex-basis: auto;
  }
}

@media (max-width: 800px) {
  .footer__column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .footer__column:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .footer__column:not(:last-child) {
    margin-bottom: 30px;
  }

  .request-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 100%;
  }

  .request-form__field--name,
  .request-form__field--phone {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
  }

  .request-form__field--message {
    width: 100%;
  }

  .request-form__check {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 55%;
    flex: 1 0 55%;
  }

  .request-form__btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
  }

  .hero {
    padding-bottom: 50px;
    background-position: center top -100%;
    //background-size: 150%;
    background-image: url(../img/first-screen-768х964.webp);
  }

  .hero__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero__content {
    margin-bottom: 300px;
  }

  .hero__descr {
    max-width: 100%;
  }

  .about-director__inner {
    display: block;
  }

  .about-director__image {
    width: 100%;
  }

  .about-director__image picture {
    height: 400px;
  }

  .about-director__info {
    width: 30%;
  }

  .about-director__text {
    max-width: 100%;
    padding: 0 40px 0;
    padding-top: calc(80px + 60 * (100vw - 320px) / 1600);
  }

  .about-director__text::before {
    left: 40px;
    top: 60px;
    width: 30px;
    height: 24px;
  }
  .object .product__title {
    font-size: 22px;
    padding-bottom: 25px;
    margin-bottom: 35px;
  }
  
  .object-slider {
      padding-bottom: 50px;
  }
  .footer_subscribe {
      max-width: 100%;
  }
}
@media (max-width: 728px) {
    .hero {
        background-position: center top -50%;
      }
}
@media (max-width: 676px) {
    .hero {
        background-position: center top -30%;
        //background-size: 150%;
      }
}
@media (max-width: 800px) and (max-width: 540px) {
  .request-form__field--name,
  .request-form__field--phone {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 768px) {
  .header__inner {
    padding: 8px 15px;
    min-height: 60px;
  }

  .header__menu {
    top: 60px;
    height: calc(100% - 60px);
  }

  .card-slider__actions {
    top: 5px;
  }
}

@media (max-width: 680px) {
  .footer__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 25px;
  }
}

@media (max-width: 600px) {
  :root {
    --borad-30: 15px;
    --borad-20: 15px;
  }

  .container {
    padding: 0 20px;
  }

  .title-h1 {
    margin-bottom: 15px;
  }

  .title-h4 {
    line-height: 1.2;
  }

  section {
    //padding-top: 60px;
    //padding-bottom: 60px;
  }

  .more-btn.icon-block {
    margin: 20px auto 0;
  }

  .breadcrumbs__list li {
    padding-right: 26px;
  }

  .breadcrumbs__list li:not(:last-child) {
    margin-right: 10px;
  }

  .breadcrumbs__list li:not(:last-child)::after {
    top: 3px;
  }

  .icon-block__info span {
    font-size: 12px;
  }

  .header__social--top {
    display: none;
  }

  .header__btn {
    padding: 11px;
  }

  .header__btn-icon {
    display: block;
  }

  .header__btn-text {
    display: none;
  }

  .header__menu {
    padding: 30px 3vw 0;
    background-image: none;
  }

  .header__menu-bottom {
    gap: 0;
    padding-top: 30px;
  }

  .header__menu-bottom .icon-block {
    margin-bottom: 15px;
  }

  .header__menu-bottom .header__social {
    margin-bottom: 30px;
  }

  .header__produce-title {
    margin-bottom: 15px;
  }

  .header__produce-list li {
    margin-bottom: 15px;
  }

  .header__produce-list a {
    font-weight: 400;
  }

  .header .two-in-row {
    gap: 0;
  }

  .header .two-in-row__column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 0;
  }

  .footer__bottom-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 30px;
  }

  .request-form {
    padding: 20px;
    border-radius: 15px;
    position: absolute;
    top: calc(100%);
  }

  .request-form__field {
    margin-bottom: 25px;
  }

  .request-form__file {
    margin-bottom: 15px;
  }

  .request-form__file .icon-block {
    font-size: 14px;
  }

  .request-form__check {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .request-form__btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .card-slider {
    padding-bottom: 120px;
  }

  .card-slider__actions {
    top: auto;
    bottom: 0;
    width: 100%;
  }

  .card-slider__btn {
    width: 100%;
    margin-left: 0;
  }

  .card-slider .swiper-pagination {
    display: block;
    bottom: 70px;
  }

  .card-rating {
    width: 155px;
  }

  .publ-date {
    margin-bottom: 10px;
  }

  .news-card__content {
    padding: 20px;
  }

  .news-card__title {
    margin-bottom: 8px;
  }

  .reviews-card {
    padding: 20px 15px;
  }

  .reviews-card__user {
    margin-bottom: 15px;
    padding-bottom: 0;
  }

  .reviews-card__user-image {
    margin-right: 10px;
    padding-left: 0;
  }

  .reviews-card__user-image img {
    width: 45px;
    min-width: 45px;
    height: 45px;
  }

  .reviews-card__user-image::before {
    display: none;
  }

  .hero {
    background-image: url(../img/first-screen-768х964.webp);
    background-position: left bottom -150px;
    background-size: contain;
  }

  .hero__content {
    margin-bottom: 146px;
    margin-right: 0;
  }

  .hero__descr {
    margin-bottom: 25px;
  }

  .hero__btn {
    width: 100%;
  }

  .hero__form {
    margin-top: -50px;
  }

  .hero-special-box {
    padding-bottom: 60px;
    display: none;
  }

  .about-section {
    --slider-padding: 20px;
    padding-bottom: 0;
  }

  .about-section__content {
    gap: 0;
    margin: 0 -20px;
  }

  .about-section__content .two-in-row__column {
    margin-bottom: 0;
  }

  .about-section__image img {
    border-radius: 0;
  }

  .about-section__slider {
    border-radius: 0;
  }

  .worth {
    margin-top: -70px;
    padding-top: calc(90px + 40 * (100vw - 320px) / 880);
  }

  .worth__slide-image img {
    height: 180px;
  }

  .worth__slide-title {
    left: -15px;
  }

  .worth__counter-current {
    font-size: 20px;
  }

  .about-director__text {
    padding: 0 20px 0;
    padding-top: calc(80px + 60 * (100vw - 320px) / 1600);
  }

  .about-director__text::before {
    left: 20px;
    top: 45px;
  }

  .certificate {
    padding-bottom: 0;
  }

  .certificate__image {
    border-radius: var(--borad-30);
    padding: 30px;
  }

  .about-common {
    padding-bottom: 0;
  }

  .about-common__image img {
    min-height: 250px;
  }

  .advantages__thumbs-wrapper {
    display: block;
    margin: 0 -20px;
    margin-top: calc(100px + 400 * (100vw - 320px) / 1330);
    border-radius: 0;
    padding: 60px 20px;
    height: 400px;
  }

  .advantages-slider {
    width: 100%;
    padding-bottom: 30px;
  }

  .advantages-thumb-slider {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    width: 45px;
    margin: 0 0 25px;
    height: 60px;
  }

  .advantages-thumb-slider__title {
    display: none;
  }

  .advantages-thumb-slide {
    padding-top: 0;
    padding-left: 0;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
  }

  .advantages-thumb-slide::before {
    background-color: #fff;
  }

  .advantages-thumb-slide:not(:last-child) {
    padding-bottom: 0;
    border: none;
  }

  .partners__inner picture {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .branches {
    padding-bottom: 0;
  }

  .catalog .product-card {
    width: calc(50% - 10px);
  }

  .product__btn {
    width: 100%;
  }

  .product__thumbs-gallery {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    height: 350px;
    max-width: 600px;
    max-height: 400px;
  }

  .product-slider {
    width: 100%;
    max-width: 600px;
  }

  .product-thumb-slider {
    width: 100%;
    max-width: 600px;
  }

  .product-thumb-slide {
    border-radius: 10px;
  }

  .product-thumb-slide img {
    max-width: 30px;
    max-height: 30px;
  }

  .contacts__block {
    padding: 20px 20px 0;
  }
  
  section.hero {
      margin-bottom: 450px;
  }
}

@media (max-width: 540px) {
  .footer__list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .footer__btn {
    width: 100%;
  }

  .news__inner {
    margin-left: 0;
  }

  .news .news-card {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 440px) {
  .popup-request.popup .popup__body {
    padding: 0;
  }

  .popup-request.popup .two-in-row__column {
    margin-bottom: 30px;
  }

  .popup-request.popup .request-form {
    border-radius: 0;
  }

  .branches__slide::before {
    left: 20px;
    top: 20px;
  }

  .branches-card__title {
    left: 20px;
  }

  .hero {
    background-position: left bottom -100px;
  }

  .about-triple {
    margin-top: 100px;
  }

  .about-triple__image {
    margin-top: -70px;
  }

  .catalog__inner {
    margin-left: 0;
  }

  .catalog .product-card {
    margin-left: 0;
    width: 100%;
  }

  .product__thumbs-gallery {
    max-height: 320px;
  }
}

@media (max-width: 380px) {
  .hero {
    background-position: left bottom -30px;
  }
}